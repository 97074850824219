import React from 'react'
// import { Link } from 'gatsby'

const Footer = props => (
  <footer id='footer'>
    <section>
      <h2>Openingstijden</h2>
      <dl>
        <dt>Kinderopvang verticale groep 0-4 jaar</dt>
        <dd> Maandag t/m vrijdag van 8:00 – 18:00</dd>
        <dt>Peuterspeelzaal 2-4 jaar</dt>
        <dd>Maandag t/m vrijdag 8:00-12:00 uitgezonderd schoolvakantie</dd>
        <dt>Buitenschoolse opvang 4-13 jaar</dt>
        <dd>
          Maandag 13.00 – 18:00<br/>
          Dinsdag en donderdag 14.00 – 18:00<br/>
          Woensdag en vrijdag 12.00-18.00<br/>
          Tijdens schoolvakanties 08.00-18.00
        </dd>
        <dt>Voorschoolse opvang zowel voor 0-4 jaar als 4-13 jaar.</dt>
        <dd>Maandag t/ vrijdag 07:00-08.30</dd>
        <dt>Avond opvang</dt>
        <dd>maandag t/m vrijdag 18.00-19.00</dd>
      </dl>
    </section>
    <section>
      <h2>Contactgegevens</h2>
      <dl className='alt'>
        <dt>Adres</dt>
        <dd>
          Vlietstraat 22 <br/>
          6542SM Nijmegen
          Nederland
        </dd>
        <dt>Tel.</dt>
        <dd>(000) 000-0000 x 0000</dd>
        <dt>Email</dt>
        <dd>
          <a href='mailto:kodespeurtocht@gmail.com'>kodespeurtocht@gmail.com</a>
        </dd>
        <dt>KvK</dt>
        <dd>
          71392033
        </dd>
        <dt>BTW</dt>
        <dd>
          NL858697816B01
        </dd>
        <dt>LRK</dt>
        <dd>
          TODO;
        </dd>
      </dl>
      <ul className='icons'>
        {/*TODO; Move to Header*/}
        <li>
          <a href='https://www.facebook.com/kodespeurtocht/' className='icon fa-facebook alt'>
            <span className='label'>Facebook</span>
          </a>
        </li>
        <li>
          <a href='https://www.instagram.com/kodespeurtocht/' className='icon fa-instagram alt'>
            <span className='label'>Instagram</span>
          </a>
        </li>
      </ul>
    </section>
  </footer>
)

export default Footer
